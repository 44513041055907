import React from 'react';
import {useAuth0} from "./react-auth0-wrapper";
import PronunciationTrainer from './components/PronunciationTrainer';

function App() {
  const {loading, isAuthenticated, loginWithRedirect} = useAuth0();

  if (loading) {
    return (
      <div className="App container">
        Loading...
      </div>
    );
  }

  if (!isAuthenticated) {
    loginWithRedirect();
    return (
      <div className="App container">
        Loading...
      </div>
    );
  }

  return (
    <div className="App container">
      <PronunciationTrainer/>
    </div>
  );
}

export default App;