import React from "react";
import {data} from "../data";
import Dictaphone from "./Dictaphone";


/**
 * Shuffles array in place. ES6 version
 * @param {Array} a items An array containing the items.
 */
function shuffle(a) {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}


class PronunciationTrainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clips: [],
      currentClip: 0,
      userInteractionDone: false
    };
  }

  getClips = () => {
    // fetch('http://localhost:5000/api/clips')
    // .then((response) => response.json())
    // .then((clips) => this.setState((state, props) => {
    //   return {clips: state.clips.concat(clips)};
    // }));
    shuffle(data);
    this.setState({clips: data});
  };

  nextClip = () => {
    this.setState((state, props) => {
      return {currentClip: (state.currentClip + 1) % state.clips.length}
    });
  };

  componentDidMount = () => {
    // get some clips from the api
    this.getClips();
  };

  render() {
    if (this.state.clips.length > 0) {
      let clip = this.state.clips[this.state.currentClip];
      return (
        <div className="pronunciation-trainer container">
          <div className="sentence-container container">
            <div className="sentence">
              <p>{clip.sentence}</p>
            </div>
          </div>
          <Dictaphone clip={clip} next={this.nextClip}/>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default PronunciationTrainer;