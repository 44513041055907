import React from "react";


const DictaphoneState = Object.freeze({
  ERROR: "ERROR",
  START: "START", /* this start state is for before the component is mounted */
  INITIALIZING_AUDIO: "INITIALIZING_AUDIO",
  PLAYING_CLIP: "PLAYING_CLIP", /* this is the start state once the component is mounted */
  PLAYING_CLIP_DONE: "PLAYING_CLIP_DONE",
  RECORDING: "RECORDING",
  RECORDING_DONE: "RECORDING_DONE",
  PLAYING_RECORDING: "PLAYING_RECORDING"
});

class Dictaphone extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      state: DictaphoneState.START,
    };

    this.chunks = [];
  }

  startRecording = () => {
    this.mediaRecorder.start();
  };

  onStartedRecording = () => {
    this.setState({state: DictaphoneState.RECORDING});
  };

  stopRecording = () => {
    this.mediaRecorder.stop();
  };

  onStoppedRecording = () => {
    let blob = new Blob(this.chunks, {'type': 'audio/webm;codecs=pcm'});
    this.chunks = [];
    this.recordingAudioEl.src = window.URL.createObjectURL(blob);

    this.setState({state: DictaphoneState.RECORDING_DONE});
    this.startPlayingRecording();
  };

  startPlayingRecording = () => {
    this.recordingAudioEl.play();
  };

  onStartedPlayingRecording = () => {
    this.setState({state: DictaphoneState.PLAYING_RECORDING});
  };

  stopPlayingRecording = () => {
    this.recordingAudioEl.pause();
  };

  onStoppedPlayingRecording = () => {
    this.startPlayingClip();
  };

  userHitPlay = () => {
    this.setState({state: DictaphoneState.INITIALIZING_AUDIO});
  };

  startPlayingClip = () => {
    this.clipAudioEl.currentTime = 0;
    this.clipAudioEl.play();
  };

  onStartedPlayingClip = () => {
    this.setState({state: DictaphoneState.PLAYING_CLIP});
  };

  stopPlayingClip = () => {
    this.clipAudioEl.pause();
  };

  onStoppedPlayingClip = () => {
    this.setState({state: DictaphoneState.PLAYING_CLIP_DONE});
    this.clipAudioElTime = 0;
  };

  onError = (data) => {
    console.log(data);
    this.setState({state: DictaphoneState.ERROR})
  };

  isStart = () => {
    return this.state.state === DictaphoneState.START;
  };

  isRecording = () => {
    return this.state.state === DictaphoneState.RECORDING;
  };

  isPlaying = () => {
    return this.state.state === DictaphoneState.PLAYING_RECORDING
      || this.state.state === DictaphoneState.PLAYING_CLIP;
  };

  isError = () => {
    return this.state.state === DictaphoneState.ERROR;
  };

  isReadyToRecord = () => {
    return !this.isStart()
      && !this.isRecording()
      && !this.isPlaying()
      && !this.isError();
  };

  initMediaRecorder = () => {
      if (navigator.mediaDevices.getUserMedia) {
        const constraints = {audio: true};

        const onSuccess = (stream) => {
          this.mediaRecorder = new MediaRecorder(stream, {mimeType: 'audio/webm;codecs=pcm'});
          this.mediaRecorder.onstart = this.onStartedRecording;
          this.mediaRecorder.onstop = this.onStoppedRecording;
          this.mediaRecorder.ondataavailable = (e) => this.chunks.push(e.data);
        };

        const onError = (err) => {
          console.log('Error recording audio: ' + err);
        };

        navigator.mediaDevices.getUserMedia(constraints)
        .then(onSuccess, onError)
        .then(() => this.startPlayingClip());

      } else {
        this.onError('getUserMedia not supported on your browser!');
      }
  };

  componentDidMount = () => {
    console.log(this.state);
  };

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (this.state.state === DictaphoneState.INITIALIZING_AUDIO) {
      this.initMediaRecorder();
    }

    if (prevProps.clip.path !== this.props.clip.path) {
      this.startPlayingClip();
    }

    console.log(this.state);
  };

  render = () => {
    let stopButtonOnClick = this.state.state === DictaphoneState.PLAYING_CLIP ?
      this.stopPlayingClip : this.stopPlayingRecording;

    return (
      <div className="dictaphone container">
        {
          this.isStart() &&
          <span className="circle-button record" onClick={this.userHitPlay}>
              <i className="fas fa-play"></i>
            </span>
        }
        {
          this.isReadyToRecord() &&
          <span className="circle-button record" onClick={this.startRecording}>
              <i className="fas fa-microphone"/>
            </span>
        }
        {
          this.isRecording() &&
          <span className="circle-button record active" onClick={this.stopRecording}>
              <i className="fas fa-microphone"/>
            </span>
        }
        {
          this.isPlaying() &&
          <span className="circle-button stop active" onClick={stopButtonOnClick}>
              <i className="fas fa-stop"/>
            </span>
        }
        {
          this.isError() &&
          <span className="circle-button record">
              <i className="fas fa-microphone-slash"/>
            </span>
        }
        <span className="circle-button next" onClick={(e) => {
          this.props.next()
        }}>
          <i className="fas fa-arrow-right"/>
        </span>

        <div>
          <audio
            ref={(ref) => {
              this.clipAudioEl = ref;
            }}
            autoPlay={false}
            onPause={this.onStoppedPlayingClip}
            onPlay={this.onStartedPlayingClip}
            src={process.env.PUBLIC_URL + this.props.clip.path}
          />
          <audio
            ref={(ref) => {
              this.recordingAudioEl = ref;
            }}
            autoPlay={false}
            onPause={this.onStoppedPlayingRecording}
            onPlay={this.onStartedPlayingRecording}
          />
        </div>
      </div>
    );
  }
}

export default Dictaphone;