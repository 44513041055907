export const data = [{
  "path": "/ita/common_voice_it_18121437.mp3",
  "sentence": "Sono pronte a condividere le loro esperienze con gli altri in maniera spontanea, inclusiva, libera e aperta \u00e8 fantastico."
}, {
  "path": "/ita/common_voice_it_18121364.mp3",
  "sentence": "Come per le revisioni."
}, {
  "path": "/ita/common_voice_it_18121436.mp3",
  "sentence": "Per questo, non erano presidiate da nessuna milizia dei sei stati."
}, {
  "path": "/ita/common_voice_it_17415843.mp3",
  "sentence": "Non deve contenere materiale pornografico, codice maligno o promozione di materiale illegale/gioco d\u2019azzardo/servizi illegali."
}, {
  "path": "/ita/common_voice_it_18121295.mp3",
  "sentence": "\"L'1 maggio 1947.\""
}, {
  "path": "/ita/common_voice_it_18121384.mp3",
  "sentence": "\"Prima del rilascio della nuova versione al comune per l'avvio dei test di pre-subentro.\""
}, {
  "path": "/ita/common_voice_it_18121342.mp3",
  "sentence": "Windows che rende entusiasti i clienti."
}, {
  "path": "/ita/common_voice_it_17415844.mp3",
  "sentence": "\"Ecce homo: chi vuol conoscere l'uomo, deve saperne riconoscere il senso, la radice e il compimento in Cristo, Dio che si abbassa per amore fino alla morte, e alla morte di croce .\""
}, {
  "path": "/ita/common_voice_it_18108297.mp3",
  "sentence": "\"Quando egli moriva nell'anno 547.\""
}, {
  "path": "/ita/common_voice_it_18121419.mp3",
  "sentence": "Avendo dovuto lo Sparviero mutare alquanto la sua rotta."
}, {
  "path": "/ita/common_voice_it_18526519.mp3",
  "sentence": "\"Questa differenza \u00e8 teorica poich\u00e9 l'esame \u00e8 il medesimo.\""
}, {
  "path": "/ita/common_voice_it_18526521.mp3",
  "sentence": "Ed \u00e8 per questo che sono pi\u00f9 attaccata a mio padre, ho trascorso pi\u00f9 tempo con lui."
}, {
  "path": "/ita/common_voice_it_18121276.mp3",
  "sentence": "Presente in un messaggio al newsgroup comp.os.minix."
}, {
  "path": "/ita/common_voice_it_17415838.mp3",
  "sentence": "Murare a secco."
}, {
  "path": "/ita/common_voice_it_17415863.mp3",
  "sentence": "Ora accade \u2013 e i giornali ne han parlato pi\u00f9 volte \u2013 che un chimico ed un inventore di Miami (Stati Uniti), Roberto Condit, si dichiara pronto\u2026 ."
}, {
  "path": "/ita/common_voice_it_18121293.mp3",
  "sentence": "La libert\u00e0 di copia, modifica e ridistribuzione non si applicano solamente al software."
}, {
  "path": "/ita/common_voice_it_18121341.mp3",
  "sentence": "Integrato in Windows 3.0."
}, {
  "path": "/ita/common_voice_it_18121275.mp3",
  "sentence": "In Oriente, la Cina supporta distribuzioni GNU/Linux nazionali, in particolare Elx Linux."
}, {
  "path": "/ita/common_voice_it_18121297.mp3",
  "sentence": "Era strano che un principe andasse a pranzare nelle stesse cucine del castello."
}, {
  "path": "/ita/common_voice_it_18121388.mp3",
  "sentence": "La quota di mercato di Windows \u00e8 oggi insidiata da quella di Linux che sta crescendo a ritmi esponenziali."
}, {
  "path": "/ita/common_voice_it_18121310.mp3",
  "sentence": "I suoi lineamenti si stagliavano fermi e regolari."
}, {
  "path": "/ita/common_voice_it_17415854.mp3",
  "sentence": "Mettere nero su bianco."
}, {
  "path": "/ita/common_voice_it_17415833.mp3",
  "sentence": "E pens\u00f2 con raccapriccio che a una di esse avrebbe battuto vanamente, perch\u00e9 di certo era vuota."
}, {
  "path": "/ita/common_voice_it_18121337.mp3",
  "sentence": "Barbicane e due cani che avrebbero fatto parte della spedizione."
}, {
  "path": "/ita/common_voice_it_18121374.mp3",
  "sentence": "\"Diventare grandi vuol dire anche aumentare lo spazio fisico, del corpo e dell'anima per fare posto alla vita.\""
}, {
  "path": "/ita/common_voice_it_18121370.mp3",
  "sentence": "\"Com'era vestito, l'artista?\""
}, {
  "path": "/ita/common_voice_it_18526514.mp3",
  "sentence": "La mano a circa met\u00e0 fase effettua una rotazione di 180\u00b0."
}, {
  "path": "/ita/common_voice_it_17415820.mp3",
  "sentence": "\"Un po' lento, ma interessante.\""
}, {
  "path": "/ita/common_voice_it_18526518.mp3",
  "sentence": "\"Richard Stallman ama definirsi l'ultimo degli hacker.\""
}, {
  "path": "/ita/common_voice_it_18121335.mp3",
  "sentence": "Buon natale a tutti!"
}, {
  "path": "/ita/common_voice_it_18121311.mp3",
  "sentence": "Con il Piano Triennale prosegue la trasformazione digitale."
}, {
  "path": "/ita/common_voice_it_17415840.mp3",
  "sentence": "Questo occorreva."
}, {
  "path": "/ita/common_voice_it_17415819.mp3",
  "sentence": "Nel legno i due personaggi sonnecchiavano."
}, {
  "path": "/ita/common_voice_it_18121363.mp3",
  "sentence": "Il principe si mise a sedere."
}, {
  "path": "/ita/common_voice_it_17415841.mp3",
  "sentence": "Le porga la chioma."
}, {
  "path": "/ita/common_voice_it_18121405.mp3",
  "sentence": "\"I beni confiscati alla criminalit\u00e0 organizzata, grazie alla legge 109/1996, vengono amministrati dall'ANSBC.\""
}, {
  "path": "/ita/common_voice_it_17415845.mp3",
  "sentence": "Avere una memoria di ferro."
}, {
  "path": "/ita/common_voice_it_17415836.mp3",
  "sentence": "\"Che cosa c'entrava lo specchio, adesso?\""
}, {
  "path": "/ita/common_voice_it_18121373.mp3",
  "sentence": "\"Invece vediamo che le linee dell'asse x si spostano verso il centro del grafico.\""
}, {
  "path": "/ita/common_voice_it_18121343.mp3",
  "sentence": "\"\u00c8 diviso in tre sezioni, di cui l'ultima termina quasi a punta ed \u00e8 munita di paracadute per la lenta discesa.\""
}, {
  "path": "/ita/common_voice_it_18121328.mp3",
  "sentence": "Il 61% della spesa totale \u00e8 destinato al software realizzato specificatamente."
}, {
  "path": "/ita/common_voice_it_17415852.mp3",
  "sentence": "Lemony Snicket, l\u2019autore della storia, fin dall\u2019inizio, avvisa lo spettatore che quello che vedr\u00e0 non sar\u00e0 piacevole."
}, {
  "path": "/ita/common_voice_it_18121329.mp3",
  "sentence": "Aggiungendo nella maggioranza dei casi anche nuove funzionalit\u00e0."
}, {
  "path": "/ita/common_voice_it_18121417.mp3",
  "sentence": "Io non desidero che la nostra Societ\u00e0 abbia noie."
}, {
  "path": "/ita/common_voice_it_18526523.mp3",
  "sentence": "Non si tratter\u00e0 di un audio realizzato da un motore di sintesi vocale."
}, {
  "path": "/ita/common_voice_it_18121292.mp3",
  "sentence": "\"Per ottenere un grafico con 2 frequenze diverse basta utilizzare un piano oscillante su cui \"\"scrive\"\" il pendolo.\""
}, {
  "path": "/ita/common_voice_it_18121416.mp3",
  "sentence": "Le sanzioni applicate sono state un primo passo per dare la possibilit\u00e0 ai concorrenti di far conoscere i propri prodotti."
}, {
  "path": "/ita/common_voice_it_18121340.mp3",
  "sentence": "Purtroppo viene valutata in tal modo anche da taluni che appartengono alla comunit\u00e0 ecclesiale."
}, {
  "path": "/ita/common_voice_it_18121360.mp3",
  "sentence": "\"L'intero corpo, sotto la pelle e persino nelle ossa \u00e8 permeato di spazi occupati dall'aria.\""
}, {
  "path": "/ita/common_voice_it_18121439.mp3",
  "sentence": "\"Il Team Digitale lavorer\u00e0 per l'individuazione di una PA che prender\u00e0 in carico il progetto.\""
}, {
  "path": "/ita/common_voice_it_18121386.mp3",
  "sentence": "Indica coloro che compiono atti di danneggiamento di sistemi informativi."
}, {
  "path": "/ita/common_voice_it_18121409.mp3",
  "sentence": "\"Era un'area molto ampia e cinta da mura.\""
}, {
  "path": "/ita/common_voice_it_17415818.mp3",
  "sentence": "Tra le carte lasciate da mio padre."
}, {
  "path": "/ita/common_voice_it_18526517.mp3",
  "sentence": "Egli si sforza di squarciare le tenebre del mito."
}, {
  "path": "/ita/common_voice_it_17415816.mp3",
  "sentence": "Tutti gli intelletti ne hanno diritto."
}, {
  "path": "/ita/common_voice_it_18526526.mp3",
  "sentence": "\"Gli utenti finali risultano ancora spaesati e lamentano difficolt\u00e0 nell'esecuzione delle normali procedure.\""
}, {
  "path": "/ita/common_voice_it_17415839.mp3",
  "sentence": "Il signorino Oscar."
}, {
  "path": "/ita/common_voice_it_18121418.mp3",
  "sentence": "Dalmor, dopo essersi rinfrescato ad una fonte alle spalle della stazione, sal\u00ec sul tetto di questa."
}, {
  "path": "/ita/common_voice_it_18121406.mp3",
  "sentence": "Microsoft ha specificato che, per scaricare i componenti rimossi, sar\u00e0 sufficiente aggiornare il sistema."
}, {
  "path": "/ita/common_voice_it_18121325.mp3",
  "sentence": "Non \u00e8 necessario approvare la spesa e quindi trovare eventuali finanziamenti."
}, {
  "path": "/ita/common_voice_it_17415862.mp3",
  "sentence": "I temi principali sono stati le implicazioni che questa normativa ha nel quotidiano per i relatori coinvolti, le prospettive future derivate da quelle che sono le proposte in dibattimento a Bruxelles e i \u201cside effect\u201d"
}, {
  "path": "/ita/common_voice_it_18121389.mp3",
  "sentence": "Creazione e distribuzione pi\u00f9 rapida delle patch di correzione rispetto ai tempi abituali."
}, {
  "path": "/ita/common_voice_it_17415837.mp3",
  "sentence": "Credi a un furto?"
}, {
  "path": "/ita/common_voice_it_17415861.mp3",
  "sentence": "conosceva bene la sua sfrontatezza, e non fu sorpreso per la domanda direttissima."
}, {
  "path": "/ita/common_voice_it_17415853.mp3",
  "sentence": "E anche un poco la cravatta gli stava di traverso e alla bottoniera della giacca mancava il fiore, dimenticato sopra il tavolo."
}, {
  "path": "/ita/common_voice_it_18121289.mp3",
  "sentence": "Oggi il Ministro ha reso pubblica la campagna."
}, {
  "path": "/ita/common_voice_it_18121313.mp3",
  "sentence": "Esisteva gi\u00e0 da molti anni."
}, {
  "path": "/ita/common_voice_it_17415851.mp3",
  "sentence": "I loro fossili sono molto importanti perch\u00e9 si trovano nelle grandi masse calcaree di quel periodo e vengono usati come fossili guida."
}, {
  "path": "/ita/common_voice_it_18121336.mp3",
  "sentence": "Chiudere la bocca a qualcuno."
}, {
  "path": "/ita/common_voice_it_18121344.mp3",
  "sentence": "\"Questi programmi vengono spesso associati all'indicazione \"\"no copyright\"\".\""
}, {
  "path": "/ita/common_voice_it_18121379.mp3",
  "sentence": "Ancora gli Inglesi le inalberano a Epsom."
}, {
  "path": "/ita/common_voice_it_17415835.mp3",
  "sentence": "ma, avvicinatosi a Delia, la prese per un braccio dolcemente, la fece alzare, la condusse verso lo studio."
}, {
  "path": "/ita/common_voice_it_18121407.mp3",
  "sentence": "\"Sull'orizzonte in fiamme il sole si spegneva nel mare.\""
}, {
  "path": "/ita/common_voice_it_18121376.mp3",
  "sentence": "Non vivendo lui nelle feroci valli di ferro."
}, {
  "path": "/ita/common_voice_it_18121378.mp3",
  "sentence": "\"E Tildor assunse il ruolo di ospite d'onore, sebbene sedesse al trono che spettava solo a lui.\""
}, {
  "path": "/ita/common_voice_it_17415817.mp3",
  "sentence": "\"A fumar l'oppio?\""
}, {
  "path": "/ita/common_voice_it_18121339.mp3",
  "sentence": "Meglio era affidarsi al caso e seguire dappresso lo svolgimento ulteriore della vicenda."
}, {
  "path": "/ita/common_voice_it_18121296.mp3",
  "sentence": "\"Un'ora, mister.\""
}, {
  "path": "/ita/common_voice_it_18121277.mp3",
  "sentence": "Dopo una prima valutazione della gravit\u00e0 della questione."
}, {
  "path": "/ita/common_voice_it_18121357.mp3",
  "sentence": "Dopo la presa da parte della Germania e della Russia della Polonia nel 1939."
}, {
  "path": "/ita/common_voice_it_17415831.mp3",
  "sentence": "Cadere in un abbaglio."
}, {
  "path": "/ita/common_voice_it_18121372.mp3",
  "sentence": "\"Far fronte alle forti limitazioni e gli ostacoli che per il forte caldo si sono presentati quest'anno nel capoluogo Sardo.\""
}, {
  "path": "/ita/common_voice_it_18526522.mp3",
  "sentence": "La decisione \u00e8 stata presa dal Parlamento solo dopo aver ascoltato il parere della societ\u00e0 di consulenza Atos Origin."
}, {
  "path": "/ita/common_voice_it_18121279.mp3",
  "sentence": "Conviene perfettamente alla respirazione dei nostri viaggiatori."
}, {
  "path": "/ita/common_voice_it_17415855.mp3",
  "sentence": "Finalmente, alle sei del mattino, di quel memorabile 19 marzo, si apr\u00ec la porta della sala e apparve il capitano Nemo."
}, {
  "path": "/ita/common_voice_it_18526525.mp3",
  "sentence": "Che accendeva solo per quello scopo."
}, {
  "path": "/ita/common_voice_it_18121294.mp3",
  "sentence": "Il contenuto \u00e8 frutto di vari studi e ricerche da esperienze passate di diverse community Mozilla."
}, {
  "path": "/ita/common_voice_it_18121338.mp3",
  "sentence": "In Italia puoi parlare con una persona quanto vuoi via email o chat."
}, {
  "path": "/ita/common_voice_it_18121326.mp3",
  "sentence": "\"Perch\u00e8 \u00e8 l'unico stile che ha una propulsione delle gambe del ben 70-80%.\""
}, {
  "path": "/ita/common_voice_it_17415832.mp3",
  "sentence": "Presa: le due mani contemporaneamente creano una presa, come se stessero aggrappandosi a qualcosa."
}, {
  "path": "/ita/common_voice_it_18121435.mp3",
  "sentence": "Oltre ad essere pi\u00f9 efficiente."
}, {
  "path": "/ita/common_voice_it_18121286.mp3",
  "sentence": "Dalmor si sdrai\u00f2 in un angolo solitario e si lasci\u00f2 prendere dal sonno."
}, {
  "path": "/ita/common_voice_it_18121408.mp3",
  "sentence": "Scopri che in stazione \u00e8 disponibile il wifi gratuito e di libero accesso!"
}, {
  "path": "/ita/common_voice_it_18526520.mp3",
  "sentence": "\"Quell'individuo.\""
}, {
  "path": "/ita/common_voice_it_17415842.mp3",
  "sentence": "Doveva esservi qualche altra ragione."
}, {
  "path": "/ita/common_voice_it_18121413.mp3",
  "sentence": "La grap start \u00e8 la partenza pi\u00f9 antica."
}, {
  "path": "/ita/common_voice_it_18121387.mp3",
  "sentence": "Apelle, figlio di Apollo fece una palla di pelle di pollo."
}, {
  "path": "/ita/common_voice_it_18121377.mp3",
  "sentence": "I cristiani separati da questa sede apostolica aspirano ad essere uniti con noi."
}, {
  "path": "/ita/common_voice_it_18121316.mp3",
  "sentence": "\"Raccontaci con una nuova storia la tua uscita dall'inverno.\""
}, {
  "path": "/ita/common_voice_it_18121362.mp3",
  "sentence": "\"Dal punto di vista dell'educazione alimentare, il giovane era una fogna, ma il kebab cos\u00ec al mattino proprio no.\""
}];